<template>
    <div id="index">
        <section id="tips">
            <p>支付成功</p>
            <!-- <p>可点击下方按钮查看结果</p> -->
            <el-button id="button" @click="goInvoice()">点击查看结果</el-button>
        </section>
        <footer>
            <!-- <img src="../assets/pointer.svg"> -->
            <!-- <div>
                <el-button id="button" @click="goInvoice()">查看</el-button>
            </div> -->
        </footer>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            go_url: null
        };
    },
    mounted(){
        const mchData = {action: 'onIframeReady', displayStyle: 'SHOW_CUSTOM_PAGE'};
        const postData = JSON.stringify(mchData);
        parent.postMessage(postData, 'https://payapp.weixin.qq.com');
        
    },
    methods: {
        goInvoice() {
            let _userToken = localStorage.getItem('user-token')
            this.go_url = 'https://hsyyh5.zjkxhpayy.shop/#/list?user-token=' + _userToken
            // this.go_url = 'https://pay.hafan.site/#/invoice?out_trade_no=' + this.$route.query.out_trade_no;
            var mchData = {
                action: 'jumpOut',
                jumpOutUrl: this.go_url // 跳转的页面
            };
            var postData = JSON.stringify(mchData);
            parent.postMessage(postData, 'https://payapp.weixin.qq.com');
        }
    }
};
</script>
<style scoped>
    #tips {
        color: #555;
        font-size: 18px;
        text-align: center;
        padding: 100px 20px;
    }
    footer {
        width: 100%;
        text-align: center;
    }
    footer img {
        width: 40px;
        margin-bottom: 20px;
    }
    #button {
        width: 68%;
        color: white;
        border-radius: 30px;
        background-color: #007FFF;
        box-shadow: 2px 2px 10px -3px #409EFF;
    }
</style>